<template>
  <div>
    <fieldsTds
      v-if="type == 'SSH' && auth == 'Private Key'"
      :fieldAttributes="{
        ...fieldAttributes,
        type: 'textarea',
        value: localValue,
      }"
      field=""
      :templateContent="result"
      @input="wrappInput"
      :value="localValue"
    ></fieldsTds>
    <input type="hidden" :value="value" :name="field" />
  </div>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
export default {
  components: { fieldsTds },
  props: ["result", "fieldAttributes", "field", "value"],
  computed: {
    localValue() {
      let value = "";
      if (typeof this.value != "undefined") {
        value = this.value;
        value = window.atob(value); // decode the string
      }
      // value = value.replace(/^%+|%+$/g, "");
      return value;
    },
    type() {
      return this.result.valueLine.c_type;
    },
    auth() {
      return this.result.valueLine.authMethod;
    },
  },
  methods: {
    wrappInput(input) {
      console.log(input);
      //to avoid pache boundarry issue
      input = window.btoa(input); // encode a string

      //  input = "%" + input + "%";
      this.$emit("input", input);
    },
  },
};
</script>